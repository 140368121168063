import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Grid,
  InputBase,
} from "@material-ui/core";
import { selectStyles } from "./styles/Select.styles";
import { withStyles } from "@material-ui/core/styles";
import { data } from "./utils/data";
import generateServices from "./utils/generateServices";
import "./App.css";

const CustomInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "0px",
    fontSize: 16,
    boxShadow: "0 4px 15px 2px rgba(0,0,0,0.1)",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Lato"].join(","),
    "&:hover": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

function App() {
  const classes = selectStyles();
  const [organizationSize, setOrganizationSize] = useState("");
  const [objective, setObjective] = useState("");
  const [objectiveOptions, setObjectiveOptions] = useState([]);
  const [url, setUrl] = useState("");

  const handleChange = (event) => {
    setOrganizationSize(event.target.value);
    setObjective("");
    let options = [];
    if (event.target.value === "1-50" || event.target.value === "50-500") {
      options = Object.keys(data)
        .map((key) => {
          if (data[key].filter((service) => service.type === "MS").length > 0) {
            return key;
          }
          return null;
        })
        .filter(Boolean);
    } else {
      options = Object.keys(data)
        .map((key) => {
          if (data[key].filter((service) => service.type === "PS").length > 0) {
            return key;
          }
          return null;
        })
        .filter(Boolean);
    }

    setObjectiveOptions([...options]);
  };

  const handleObjectiveChange = (event) => {
    setObjective(event.target.value);
    const type =
      organizationSize === "1-50" || organizationSize === "50-500"
        ? "MS"
        : "PS";
    const targetService = generateServices(type, event.target.value);
    setUrl(targetService[0].url);
  };

  return (
    <Box className="App" style={{ height: "100%" }}>
      <Grid container style={{ height: "100%", display: "flex" }}>
        <Grid
          item
          xs={0}
          sm={2}
          md={4}
          style={{ background: "#0168F8", height: "100vh" }}
        >
          <Box className={classes.background} />
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
          <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Box ml={3} mr={3}>
              <Box
                style={{
                  color: "#0168F8",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                CISOSHARE SERVICES
              </Box>
              <Box
                mt={2}
                style={{
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                  textTransform: "capitalize",
                }}
              >
                Let's Find the Right Solution!
              </Box>
              <Box mt={2} style={{ fontSize: "1rem", textAlign: "left" }}>
                Tell us about your organization and your main objective and
                we'll suggest the solution that best helps you meet your goals.
              </Box>

              <Grid
                container
                display="flex"
                alignItems="flex-end"
                spacing={2}
                style={{ marginTop: 20 }}
              >
                <Grid item xs={12} sm={12} md={3}>
                  <FormControl required style={{ width: "100%" }}>
                    <InputLabel
                      style={{ color: "#000", fontWeight: "bold" }}
                      shrink
                    >
                      Organization Size
                    </InputLabel>
                    <Select
                      IconComponent={() => (
                        <i
                          className="fas fa-chevron-down"
                          style={{
                            color: "#0168F8",
                            position: "absolute",
                            top: 12,
                            right: "0",
                            display: "inline-block",
                            width: "24px",
                            height: "24px",
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      displayEmpty
                      value={organizationSize}
                      onChange={handleChange}
                      fullWidth
                      input={<CustomInput />}
                      className={classes.selectEmpty}
                    >
                      <MenuItem
                        onMouseEnter={(e) => {
                          e.target.style.background = "#0168F8";
                          e.target.style.color = "#fff";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.background = "#fff";
                          e.target.style.color = "#000";
                        }}
                        value=""
                        classes={{ selected: classes.selected }}
                      >
                        None
                      </MenuItem>
                      <MenuItem
                        onMouseEnter={(e) => {
                          e.target.style.background = "#0168F8";
                          e.target.style.color = "#fff";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.background = "#fff";
                          e.target.style.color = "#000";
                        }}
                        value={"1-50"}
                        classes={{ selected: classes.selected }}
                      >
                        1-50
                      </MenuItem>
                      <MenuItem
                        onMouseEnter={(e) => {
                          e.target.style.background = "#0168F8";
                          e.target.style.color = "#fff";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.background = "#fff";
                          e.target.style.color = "#000";
                        }}
                        value={"50-500"}
                        classes={{ selected: classes.selected }}
                      >
                        50-500
                      </MenuItem>
                      <MenuItem
                        onMouseEnter={(e) => {
                          e.target.style.background = "#0168F8";
                          e.target.style.color = "#fff";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.background = "#fff";
                          e.target.style.color = "#000";
                        }}
                        value={"501-1000"}
                        classes={{ selected: classes.selected }}
                      >
                        501-1000
                      </MenuItem>
                      <MenuItem
                        onMouseEnter={(e) => {
                          e.target.style.background = "#0168F8";
                          e.target.style.color = "#fff";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.background = "#fff";
                          e.target.style.color = "#000";
                        }}
                        value={"1000+"}
                        classes={{ selected: classes.selected }}
                      >
                        1000+
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={5} style={{ width: "100%" }}>
                  <FormControl required fullWidth>
                    <InputLabel
                      shrink
                      style={{ color: "#000", fontWeight: "bold" }}
                    >
                      Objective
                    </InputLabel>
                    <Select
                      disabled={
                        !organizationSize || organizationSize.length === 0
                      }
                      IconComponent={() => (
                        <i
                          className="fas fa-chevron-down"
                          style={{
                            color: "#0168F8",
                            position: "absolute",
                            top: 12,
                            right: "0",
                            display: "inline-block",
                            width: "24px",
                            height: "24px",
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      input={<CustomInput />}
                      displayEmpty
                      value={objective}
                      onChange={handleObjectiveChange}
                      className={classes.selectEmpty}
                    >
                      <MenuItem
                        classes={{ selected: classes.selected }}
                        onMouseEnter={(e) => {
                          e.target.style.background = "#0168F8";
                          e.target.style.color = "#fff";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.background = "#fff";
                          e.target.style.color = "#000";
                        }}
                        value=""
                      >
                        None
                      </MenuItem>
                      {objectiveOptions.map((objective, index) => {
                        return (
                          <MenuItem
                            classes={{ selected: classes.selected }}
                            onMouseEnter={(e) => {
                              e.target.style.background = "#0168F8";
                              e.target.style.color = "#fff";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.background = "#fff";
                              e.target.style.color = "#000";
                            }}
                            key={index}
                            value={objective}
                          >
                            {objective}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={4} style={{ width: "100%" }}>
                  <Button
                    classes={{
                      root: classes.root,
                      label: classes.label,
                    }}
                    disabled={
                      !organizationSize ||
                      organizationSize.length === 0 ||
                      !objective ||
                      objective.length === 0
                    }
                    onClick={() => window.open(url, "_self")}
                  >
                    FIND SOLUTION
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
