import { makeStyles } from "@material-ui/core/styles";
import img from "../img/img.png";

export const selectStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #03AAFA 30%, #1A3CF6 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(26,60,246,.2)",
  },
  label: {
    textTransform: "capitalize",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  background: {
    backgroundImage: `url(${img})`,
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  selected: {
    backgroundColor: "#0168F8 !important",
    color: "white !important",
  },
}));
