import { data } from "./data";

export default function generateServices(serviceType, objective) {
  if (arguments.length !== 2) {
    return `Two arguments required (serviceType and objective), received ${arguments.length}`;
  }

  const services =
    data[objective] &&
    data[objective].filter((service) => service.type === serviceType);

  if (!services) {
    return "Invalid Objective";
  }

  if (services.length === 0) {
    return "No Services";
  }

  return services;
}
