/**
 * The data is formatted so that the business objective is the key of the
 * object and the services are the values, in an array of objects. When looking
 * to see the services to display on the UI, locate the key in the objective and then
 * map over each of the elements in the array to display that service
 */
export const data = {
  "Align to a Framework (Best Practice)": [
    {
      service: "Managed Security Program",
      type: "MS",
      url: "https://cisoshare.com/managed-security-services/managed-security-program/",
      icon: "fal fa-pencil",
    },
  ],
  "Appease Clients": [
    {
      service: "CISO-as-a-Service (Virtual CISO)",
      type: "MS",
      url: "https://cisoshare.com/ciso-services/",
      icon: "fal fa-pencil",
    },
  ],
  "Improve Maturity Over Time": [
    {
      service: "CISO-as-a-Service (Virtual CISO)",
      type: "MS",
      url: "https://cisoshare.com/ciso-services/",
      icon: "fal fa-pencil",
    },
  ],
  "Increase Security of Organization": [
    {
      service: "Managed Security Program",
      type: "MS",
      url: "https://cisoshare.com/managed-security-services/managed-security-program/",
      icon: "fal fa-pencil",
    },
  ],
  "Prevent Breach": [
    {
      service: "Third-Party Vendor Management",
      type: "MS",
      url: "https://cisoshare.com/managed-security-services/third-party-risk-management-services/",
      icon: "fal fa-pencil",
    },
  ],
  "Prevent Fines": [
    {
      service: "Compliance and Frameworks",
      type: "PS",
      url: "https://cisoshare.com/services/compliance-best-practice-services/",
      icon: "fal fa-pencil",
    },
  ],
  "Recover From Breach": [
    {
      service: "Data Privacy Program",
      type: "PS",
      url: "https://cisoshare.com/services/data-privacy-services/",
      icon: "fal fa-pencil",
    },
  ],
  "Reduce Security Risk": [
    {
      service: "Penetration Testing",
      type: "PS",
      url: "https://cisoshare.com/services/penetration-testing-services/",
      icon: "fal fa-pencil",
    },
  ],
  "Understand Current State": [
    {
      service: "Security Program Assessment",
      type: "PS",
      url: "https://cisoshare.com/services/security-program-assessment/",
      icon: "fal fa-pencil",
    },
  ],
  "Get Certified": [
    {
      service: "Security Program Development",
      type: "PS",
      url: "https://cisoshare.com/services/security-program/development/",
      icon: "fal fa-pencil",
    },
  ],
  "Improve Privacy": [
    {
      service: "Data Privacy Program",
      type: "PS",
      url: "https://cisoshare.com/services/data-privacy-services/",
      icon: "fal fa-pencil",
    },
  ],
};
